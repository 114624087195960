import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

const app = createApp(App);

app.config.productionTip = false;

import vSelect from "vue-select";
app.component("v-select", vSelect);

import VueGtag from "vue-gtag";
if (process.env.NODE_ENV === "production") {
  app.use(VueGtag, {
    config: { id: "UA-103555680-10" },
  });
}

import store from "./store";
app.use(store);

app.mount("#app");
